<template>
  <div class="tracesource-authorizationList">
    <!--溯源可在线授权列表-->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="在线授权列表"
      :visible.sync="showList"
      class="authorize-list"
    >
      <div class="content">
        <ul class="file-list">
          <li
            class="file-list-item"
            v-for="(item, index) in authorizeList"
            :key="index"
          >
            <span class="file-list-item-left">
              <span class="num">+{{ item.number }}</span>
              <span class="file-name">{{ item.fileName }}</span>
            </span>
            <span class="auth" @click="showAuthorizeDetail(item.textMd5)"
              >在线授权</span
            >
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findTraceAuthorizationList } from "api/traceTheSource";
export default {
  name: "TraceSourceAuthorizationList",
  props: {
    showAuthorizeList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showList: this.showAuthorizeList,
      //可在线授权列表数据
      authorizeList: [],
    };
  },
  methods: {
    /***
     * 查找溯源可在线授权列表
     * @param { Number } id 当前文件id
     */
    async findAuthorizeList(id) {
      const params = { reportId: id };
      const res = await findTraceAuthorizationList(params);
      if (res.code == 200) {
        this.authorizeList = res.data;
      }
    },
    /***
     * 显示授权许可
     * @param { String } textMd5 文件md5值
     */
    showAuthorizeDetail(textMd5) {
      this.$emit("showAuthorizeDetail", { textMd5 });
    },
  },
  watch: {
    showAuthorizeList(val) {
      this.showList = val;
    },
  },
  created() {
    this.findAuthorizeList();
  },
};
</script>

<style lang="scss" scoped>
.tracesource-authorizationList {
  //可在线授权列表
  .authorize-list {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 497px;
      height: 421px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 14px 9px 13px;
        background: #f4f6f7;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #333;
        }
        .el-dialog__headerbtn {
          top: 18px;
          right: 12px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 30px 5px 30px 30px;
        .content {
          height: 310px;
          .file-list {
            height: 100%;
            padding-right: 30px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track {
              box-shadow: none;
              background-color: #fff;
            }
            &-item {
              @include flex-between;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              &-left {
                @include flex-start;
              }
              .num {
                @include ellipsis;
                max-width: 80px;
                color: #f95656;
              }
              .file-name {
                @include ellipsis;
                width: 260px;
                margin-left: 10px;
              }
              .auth {
                margin-left: 10px;
                margin-right: 10px;
                color: #ff6900;
                text-decoration: underline;
                cursor: pointer;
              }
              &:first-child ~ .file-list-item {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="tracesource-history">
    <div class="tracesource-history-header">
      <div class="goback" @click="goBack"><i class="back"></i>返回</div>
      <span class="title">历史溯源报告</span>
      <span class="refresh" @click="refresh"
        ><img :src="require('assets/images/refresh-gray.png')" alt=""
      /></span>
    </div>
    <div class="tracesource-history-reportform">
      <div class="tracesource-history-reportform-search">
        <div class="query-filename">
          <el-input
            placeholder="请输入文件名搜索"
            suffix-icon="el-icon-search"
            v-model="queryFileName"
            @change="searchFileName"
          >
          </el-input>
        </div>
        <div class="query-date">
          <el-date-picker
            v-model="queryDate"
            type="date"
            placeholder="请选择日期搜索"
            value-format="yyyy-MM-dd"
            @change="searchDate"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="tracesource-history-reportform-table">
        <el-table :data="traceReportList" style="width: 100%">
          <el-table-column prop="" label="序号" width="50">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column label="文件名">
            <template slot-scope="scope">
              {{ scope.row.fileName }}
            </template>
          </el-table-column>

          <el-table-column
            label="类型"
            :sortable="true"
            :sort-method="sortType"
          >
            <template slot-scope="scope">
              {{
                scope.row.reportType == 1
                  ? '语篇'
                  : scope.row.reportType == 2
                  ? '图片'
                  : '视频'
              }}
            </template>
          </el-table-column>

          <el-table-column label="数据大小">
            <template slot-scope="scope">{{ scope.row.fs }}</template>
          </el-table-column>

          <el-table-column label="价格">
            <template slot-scope="scope">{{ scope.row.price }}字符</template>
          </el-table-column>

          <el-table-column prop="timeSpan" label="溯源时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>

          <el-table-column label="溯源评价" width="150">
            <template slot-scope="{ row: { reportType, level, evaluation } }">
              <div class="evaluate-wrap">
                <div class="nothing" v-if="!evaluation">未溯源</div>
                <div v-if="reportType == 1 && evaluation" class="original">
                  <i
                    class="levelIcon"
                    :class="
                      level == 'A+'
                        ? 'Aadd'
                        : level == 'A'
                        ? 'A'
                        : level == 'B+'
                        ? 'Badd'
                        : level == 'B'
                        ? 'B'
                        : 'C'
                    "
                  ></i>
                  <span class="txt">严格原创率{{ evaluation }}%</span>
                </div>
                <div v-if="reportType > 1 && evaluation" class="homology">
                  <img
                    v-if="evaluation == '有同源'"
                    :src="require('assets/images/homology-y.png')"
                    alt=""
                  />
                  <img
                    v-else
                    :src="require('assets/images/homology-n.png')"
                    alt=""
                  />
                  <span
                    :class="[
                      'txt',
                      evaluation == '有同源'
                        ? 'is-homology'
                        : evaluation == '无同源'
                        ? 'no-homology'
                        : '',
                    ]"
                  >
                    {{ evaluation }}
                  </span>
                </div>
                <!-- <div v-if="scope.row.evaluateType == 0">无</div> -->
              </div>
            </template>
          </el-table-column>

          <el-table-column label="在线授权">
            <template slot-scope="scope">
              <span
                :class="[
                  'authorize',
                  scope.row.originalIds && scope.row.originalIds != ''
                    ? 'is-blue'
                    : 'is-gray',
                ]"
                @click="showAuthorize(scope.row.originalIds, scope.row.id)"
              >
                {{
                  scope.row.originalIds && scope.row.originalIds != ''
                    ? '可在线授权'
                    : '无在线授权'
                }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="溯源报告" width="108">
            <template slot-scope="{ row: { reportUrl: pdf, id } }">
              <div class="operate">
                <span class="view" @click="openPdf(pdf)"><i></i></span>
                <span class="share" @click="QrCodeShow(pdf)"><i></i></span>
                <span class="delete" @click="delHandler(id)"><i></i></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="tracesource-history-footer">
      <Pager
        :hide-on-single-page="true"
        :pageNum="pageNum"
        :pageSize="pageCount"
        @currentChange="currentChange"
      />
    </div>

    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
      </div>
    </el-dialog>
    <TraceSourceAuthorizationList
      ref="traceAuthorizeList"
      :show-authorize-list="showAuthorizeList"
      @showAuthorizeDetail="showAuthorizeInfo"
    />
    <TraceSourceAuthorizationInfo ref="traceAuthorize" />
  </div>
</template>

<script>
import QrCode from 'qrcodejs2'
import { findTraceReport, delHisTraceSource } from 'api/traceTheSource'
import { previewUrl } from 'api/common'
import TraceSourceAuthorizationList from 'components/traceSource/TraceSourceAuthorizationList'
import TraceSourceAuthorizationInfo from 'components/traceSource/TraceSourceAuthorizationInfo'
import Pager from 'components/idiom/pager/IdiomPager'
export default {
  name: 'TraceSourceHistory',
  data() {
    return {
      // 分享二维码弹窗
      QrCodeVisible: false,
      qr: '',
      traceReportList: [], //历史溯源报告表格数据
      traceReportTotal: 0, //总条数
      //原创等级
      originalLang: [
        {
          level: 1,
          icon: require('assets/images/original-a-plus.png'),
        },
        {
          level: 2,
          icon: require('assets/images/original-a.png'),
        },
        {
          level: 3,
          icon: require('assets/images/original-b-plus.png'),
        },
        {
          level: 4,
          icon: require('assets/images/original-b.png'),
        },
        {
          level: 5,
          icon: require('assets/images/original-c.png'),
        },
      ],
      //当前页
      pageNum: 1,
      //一页显示的大小
      pageSize: 6,
      //总页数
      pageCount: 1,
      //默认服务器路径
      basePath: 'http://fafs.antiplagiarize.com/',
      fullPreviewPdf: '',
      //文件名搜索
      queryFileName: '',
      //时间搜索
      queryDate: '',
      //当前搜索条件
      searchKey: '',
      //显示可在线授权列表
      showAuthorizeList: false,
    }
  },
  methods: {
    //返回
    goBack() {
      this.$router.go(-1)
    },
    //文件名搜索
    async searchFileName() {
      this.pageNum = 1
      this.searchKey = 'key'
      await this.queryReportByCondition(this.searchKey, this.queryFileName)
    },
    //时间搜索
    async searchDate() {
      this.pageNum = 1
      this.searchKey = 'date'
      this.queryReportByCondition(this.searchKey, this.queryDate)
    },
    //搜索条件查询报表
    async queryReportByCondition(key, data) {
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
        [key]: data,
      }
      this.$store.commit('playLifeLoading', true)
      const res = await findTraceReport(params)
      this.$store.commit('playLifeLoading', false)
      if (res.code === 200) {
        this.traceReportList = res.data.data
        this.traceReportTotal = res.data.total
        this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize)
      } else {
        this.traceReportList = []
        this.traceReportTotal = 0
      }
    },
    //点击删除事件
    delHandler(reportId) {
      this.$confirm('是否删除该文件', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.delReport(reportId)
        this.gettraceList()
      })
    },
    //删除报表
    async delReport(reportId) {
      const params = { reportId }
      this.$store.commit('playLifeLoading', true)
      const res = await delHisTraceSource(params)
      this.$store.commit('playLifeLoading', false)
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功',
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    // 生成二维码
    crateQrcode(pdf) {
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: this.basePath + pdf,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
      })
    },
    // 打开分享二维码弹窗
    QrCodeShow(pdf) {
      this.QrCodeVisible = true
      this.$nextTick(() => {
        this.crateQrcode(pdf)
      })
    },
    // 关闭去除二维码
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = ''
    },
    //打开报表pdf
    async openPdf(pdf) {
      if (pdf) {
        this.$store.commit('playLifeLoading', true)
        await this.previewPdf(pdf)
        this.$store.commit('playLifeLoading', false)
        if (this.fullPreviewPdf) {
          window.open(this.fullPreviewPdf)
          this.fullPreviewPdf = ''
        }
      } else {
        this.$message({
          type: 'success',
          message: '暂无报告',
        })
      }
    },
    //获取完整编码的预览地址
    async previewPdf(source) {
      const params = {
        url: this.basePath + source,
      }
      const res = await previewUrl(params)
      if (res.code == 200) {
        this.fullPreviewPdf = res.data
      }
    },
    //刷新
    async refresh() {
      this.$store.commit('playLifeLoading', true)
      await this.gettraceList()
      this.$store.commit('playLifeLoading', false)
    },
    // pageNum改变触发
    currentChange(cur) {
      let searchValue = this.queryFileName || this.queryDate
      this.pageNum = cur
      if (searchValue) {
        this.queryReportByCondition(this.searchKey, searchValue)
      } else {
        this.gettraceList()
      }
    },
    getOriginalIcon(l) {
      return this.originalLang.filter((i) => i.level === l)[0].icon
    },
    sortType() {},
    // 获取表格数据
    async gettraceList() {
      const data = {
        page: this.pageNum,
        limit: this.pageSize,
      }
      this.$store.commit('playLifeLoading', true)
      const res = await findTraceReport(data)
      this.$store.commit('playLifeLoading', false)
      if (res.code === 200) {
        this.traceReportList = res.data.data
        this.traceReportTotal = res.data.total
        this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize)
      } else {
        this.traceReportList = []
        this.traceReportTotal = 0
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    /***
     * 点击在线授权
     * @param { String } ids 没有ids表示无在线授权
     * @param { Number } id 当前文件id
     */
    showAuthorize(ids, id) {
      if (!ids) return
      this.showAuthorizeList = true
      this.$refs['traceAuthorizeList'].findAuthorizeList(id)
    },
    //显示授权许可信息
    showAuthorizeInfo(item) {
      this.$refs['traceAuthorize'].authorization(item)
    },
  },
  components: {
    TraceSourceAuthorizationList,
    TraceSourceAuthorizationInfo,
    Pager,
  },
  created() {
    this.gettraceList()
  },
}
</script>

<style lang="scss" scoped>
.tracesource-history {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  min-width: 644px;
  min-height: 550px;
  &-header {
    @include flex-center;
    position: relative;
    height: 45px;
    border-bottom: 1px solid #eef0f4;
    .goback {
      @include flex-start;
      @include noselect;
      position: absolute;
      top: 16px;
      left: 20px;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      .back {
        position: relative;
        width: 6.3px;
        height: 11px;
        margin-right: 3px;
        margin-bottom: 1px;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: url('~assets/images/arrow-left.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .refresh {
      @include noselect;
      position: absolute;
      top: 16px;
      right: 20px;
      display: inline-block;
      width: 15px;
      height: 14px;
      cursor: pointer;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
    }
  }
  &-reportform {
    // flex: 1;
    padding: 0 10px;
    &-search {
      @include flex-start;
      margin-top: 20px;
      > div {
        ::v-deep .el-input {
          input {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .query-date {
        margin-left: 20px;
      }
    }
    &-table {
      margin-top: 30px;
      ::v-deep .el-table::before {
        background-color: transparent;
      }
      ::v-deep .el-table td.el-table__cell {
        border-bottom: none;
        &:first-child {
          @include noselect;
        }
        .cell {
          @include ellipsis;
          .evaluate-wrap {
            @include noselect;
            font-size: 12px;
            font-weight: 500;
            .nothing {
              color: #999;
            }
            .original,
            .homology {
              @include flex-start;
              .levelIcon {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
              .Aadd {
                @include backgroundGroup('~assets/images/A+.png');
              }
              .A {
                @include backgroundGroup('~assets/images/A.png');
              }
              .Badd {
                @include backgroundGroup('~assets/images/B+.png');
              }
              .B {
                @include backgroundGroup('~assets/images/B.png');
              }
              .C {
                @include backgroundGroup('~assets/images/c.png');
              }
              .txt {
                margin-left: 5px;
                color: #4587ff;
              }
            }
            .homology {
              .txt {
                &.is-homology {
                  color: #e15240;
                }
                &.no-homology {
                  color: #4587ff;
                }
              }
            }
          }
          .authorize {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
          }
          .is-blue {
            color: #4587ff;
            cursor: pointer;
          }
          .is-gray {
            color: #999999;
          }
          .operate {
            @include flex-between;
            span {
              @include flex-start;
              cursor: pointer;
              i {
                position: relative;
                width: 16px;
                height: 16px;
              }
            }
            .view i,
            .share i,
            .delete i {
              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
              }
            }
            .view i::before {
              background: url('~assets/images/report-view-gray.png') no-repeat;
            }
            .view:hover i::before {
              background: url('~assets/images/report-view-blue.png') no-repeat;
            }
            .share i::before {
              background: url('~assets/images/report-share-gray.png') no-repeat;
            }
            .share:hover i::before {
              background: url('~assets/images/report-share-blue.png') no-repeat;
            }
            .delete i::before {
              background: url('~assets/images/delete.png') no-repeat;
            }
            .delete:hover i::before {
              background: url('~assets/images/delete-blue.png') no-repeat;
            }
          }
        }
      }
      ::v-deep .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
    }
  }
  &-footer {
    @include noselect;
    margin: 21px auto;
    ::v-deep .el-pagination {
      button {
        width: 80px;
      }
    }
  }

  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
